
import { Component, Vue, Watch } from "vue-property-decorator"
import { ScopeLibraryTreeDataQuery, ScopeServiceGroup } from "@/gql"
import AppPage from "@/components/layout/AppPage.vue"
import { tree } from "vued3tree"
import Loader from "@/components/widgets/common/Loader.vue"
import ViewToggle from "@/components/widgets/common/ViewToggle.vue"
import * as d3 from "d3"

@Component({
  components: {
    AppPage,
    tree,
    Loader,
    ViewToggle,
  },
  apollo: {
    queryData: {
      query: ScopeLibraryTreeDataQuery,
      update(data: { scopeServiceGroups: ScopeServiceGroup[] }) {
        return data.scopeServiceGroups
      },
      loadingKey: "loading",
    },
  },
})
export default class TreeView extends Vue {
  queryData: ScopeServiceGroup[] = []
  loading = 0

  get treeData() {
    return {
      name: "Scope",
      children: [
        ...this.queryData.map((g) => ({
          name: g.name,
          children: g.services.map((s) => ({
            name: s.name,
            children: s.phases.map((p) => ({
              name: p.name,
              children: p.activities.map((a) => ({
                name: this.norm(a.name),
              })),
            })),
          })),
        })),
      ],
    }
  }

  norm(name: string) {
    return this.truncate(name)
  }

  onZoomReset() {
    ;(this.$refs.tree as any).resetZoom()
  }

  @Watch("treeData")
  onDataLoaded() {
    if (this.treeData) {
      this.$nextTick(() => {
        // TODO: RUN THIS ONLY SVG TRANSFORM
        setTimeout(() => {
          d3.selectAll(".treeclass .nodetree text")
            .append("title")
            .text(function (d: any) {
              return d.data.name
            })
        }, 1000)
      })
    }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('AppPage',{attrs:{"title":"Scope Library"},scopedSlots:_vm._u([{key:"tools",fn:function(){return [_c('view-toggle',{attrs:{"views":[
        {
          name: 'List',
          icon: 'la-th-list',
          route: _vm.$routes.ScopeLibrary,
        },
        {
          name: 'Tree',
          icon: 'la-sitemap',
          route: _vm.$routes.ScopeLibraryTreeView,
        },
      ]}})]},proxy:true}])},[(_vm.loading)?_c('Loader',{attrs:{"text":"Loading tree view...","fullPage":true}}):_c('div',[_c('div',{staticClass:"d-flex mb-3"},[_c('v-spacer'),_c('text-button',{attrs:{"color":"secondary"},on:{"click":_vm.onZoomReset}},[_vm._v("Reset zoom")])],1),_c('div',{staticClass:"tree-wrap",staticStyle:{"max-width":"100%","height":"70vh","background":"white","border-radius":"10px"}},[_c('tree',{ref:"tree",attrs:{"data":_vm.treeData,"node-text":"name","layoutType":"horizontal","zoomable":true}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }